import { apiConfig } from '../plugins/config'
import axios from 'axios'

export default {
  login (email, password) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'login',
      data: {
        email: email,
        password: password,
      },
    })
  },
  adLogin (adUser) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'adLogin',
      data: {
        username: adUser.username,
        password: adUser.password,
      },
    })
  },
  googleLogin (token) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'googleLogin',
      data: {
        token: token,
      },
    })
  },
  logout () {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'logout',
    })
  },
}
