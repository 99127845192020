<template>
  <v-menu
    bottom
    left
    min-width="200"
    offset-y
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        class="ml-2"
        min-width="0"
        text
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </template>

    <v-list
      :tile="false"
      flat
      nav
    >

      <template v-for="(p, i) in profile">
        <v-divider
          v-if="p.divider"
          :key="`divider-${i}`"
          class="mb-2 mt-2"
        />

        <app-bar-item
          v-else
          :key="`item-${i}`"
        >
          <v-list-item-title
            @click="jumperTo(p.routeName)"
            v-text="p.title"
          />
        </app-bar-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
  import store from '@/store'
  import userAuthApi from '@/api/user-authentication'
  export default {
    name: 'DefaultAccount',

    data: () => ({
      profile: [
        { title: 'Profile', routeName: 'UserProfile' },
        { divider: true },
        // { title: 'Settings', routeName: 'UserProfile' },
        { title: 'Log out', routeName: 'logout' },
      ],
    }),
    methods: {
      jumperTo (name) {
        if (name === 'logout') {
          this.logout()
        } else {
          this.$router.push({ name: name }).catch(() => {})
        }
        /*
        switch (name) {
          case 'UserProfile':
            this.goUserProfile()
            break
          case 'logout':
            this.logout()
            break
        }
        */
      },
      logout () {
        userAuthApi.logout()
        store.set('user/clean')
        this.$router.push({ path: '/login' })
      },
    },
  }
</script>
